
@import '../../core-ui/_base'
//FOOTER
.footer
    padding: 6rem 1rem
    justify-content: center
    display: flex
    flex-direction: row
    a
        font-family: $codingfont
        color: $white
        opacity: 0.4
